import ReactGA from 'react-ga4'
import moment from 'moment'
import { gaTrackersNames, googleAnalyticsConfig } from '../settings'
import {
    ALL_SOLUTIONS,
    CONDITIONS_RELATION_OPERATORS,
    DIMENSIONS,
    FILTERS_RELATION_OPERATORS,
    metricBySolution,
    PERCENTAGE_SUFFIX,
    TOTAL_COLUMN,
} from '../protectedMediaDashboardApp/containers/dashboard/config'
import { SOLUTIONS } from '../protectedMediaDashboardApp/config'

export function clearToken() {
    localStorage.removeItem('id_token')
}

export function setToken(token) {
    localStorage.setItem('id_token', token);
}

export function getToken() {
    try {
        return localStorage.getItem('id_token');
    } catch (err) {
        clearToken();
    }
}

export function timeDifference(givenTime) {
    givenTime = new Date(givenTime);
    const milliseconds = new Date().getTime() - givenTime.getTime();
    const numberEnding = number => {
        return number > 1 ? 's' : '';
    };
    const number = num => (num > 9 ? '' + num : '0' + num);
    const getTime = () => {
        let temp = Math.floor(milliseconds / 1000);
        const years = Math.floor(temp / 31536000);
        if (years) {
            const month = number(givenTime.getUTCMonth() + 1);
            const day = number(givenTime.getUTCDate());
            const year = givenTime.getUTCFullYear() % 100;
            return `${day}-${month}-${year}`;
        }
        const days = Math.floor((temp %= 31536000) / 86400);
        if (days) {
            if (days < 28) {
                return days + ' day' + numberEnding(days);
            } else {
                const months = [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                ];
                const month = months[givenTime.getUTCMonth()];
                const day = number(givenTime.getUTCDate());
                return `${day} ${month}`;
            }
        }
        const hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
            return `${hours} hour${numberEnding(hours)} ago`;
        }
        const minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
            return `${minutes} minute${numberEnding(minutes)} ago`;
        }
        return 'a few seconds ago';
    };
    return getTime();
}

export function stringToInt(value, defValue = 0) {
    if (!value) {
        return 0;
    } else if (!isNaN(value)) {
        return parseInt(value, 10);
    }
    return defValue;
}

export function stringToPosetiveInt(value, defValue = 0) {
    const val = stringToInt(value, defValue);
    return val > -1 ? val : defValue;
}

export function commaThousandsSeparator(number) {
    return !isNaN(number) && number != null ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'N/A'
}

export function calcPercentage(part, total) {
    let percentage = total > 0 ? (part / total * 100) : part;
    if (!isNaN(percentage)) {
        return parseFloat(parseFloat(percentage).toFixed(percentage < 1 ? 2 : 1))
    }
}

export function formatPercentageForDataTable(value, total) {
    if (!isNaN(value)) {
        return calcPercentage(value, total) + '%'
    }
    return 'N/A';
}

export function formatLargeNumbers(x, minimumDigits) {
    if (isNaN(x)) return x;
    if (!minimumDigits || x.toString().length > minimumDigits) {
        if (x < 9999) {
            return commaThousandsSeparator(x);
        }
        if (x < 1000000) {
            return Math.round(x / 1000) + "K";
        }
        if (x < 10000000) {
            return parseFloat((x / 1000000).toFixed(2)).toString() + "M";
        }

        if (x < 1000000000) {
            return Math.round((x / 1000000)) + "M";
        }
        return commaThousandsSeparator(Math.round((x / 1000000000))) + "B";
    } else return commaThousandsSeparator(x)
}

export function areArraysEqual(firstArray, secondArray) {
    if (!(firstArray && secondArray))
        return false;

    if (firstArray.length !== secondArray.length)
        return false;

    for (var i = 0, l = firstArray.length; i < l; i++) {
        if (firstArray[i] instanceof Array && secondArray[i] instanceof Array) {
            if (!areArraysEqual(firstArray[i], secondArray[i]))
                return false;
        }

        if (firstArray[i] instanceof Object && secondArray[i] instanceof Object) {
            if (!areObjectsEqual(firstArray[i], secondArray[i]))
                return false;
        } else if (firstArray[i] !== secondArray[i]) {
            return false;
        }
    }
    return true;
}

export function areObjectsEqual(firstObject, secondObject) {
    for (let propName in firstObject) {
        if (firstObject.hasOwnProperty(propName) !== secondObject.hasOwnProperty(propName)) {
            return false;
        } else if (typeof firstObject[propName] !== typeof secondObject[propName]) {
            return false;
        }
    }
    for (let propName in secondObject) {
        if (firstObject.hasOwnProperty(propName) !== secondObject.hasOwnProperty(propName)) {
            return false;
        } else if (typeof firstObject[propName] !== typeof secondObject[propName]) {
            return false;
        }
        if (!firstObject.hasOwnProperty(propName))
            continue;

        if (firstObject[propName] instanceof Array && secondObject[propName] instanceof Array) {
            if (!areArraysEqual(firstObject[propName], secondObject[propName]))
                return false;
        } else if (firstObject[propName] instanceof Object && secondObject[propName] instanceof Object) {
            if (!areObjectsEqual(firstObject[propName], secondObject[propName]))
                return false;
        } else if (firstObject[propName] !== secondObject[propName]) {
            return false;
        }
    }
    return true;
}

export function validateEmailAddress(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isPasswordValid(password) {
    const re = /^(?=.*[A-Za-z])(?=.*[0-9!@#$%^&*])[A-Za-z\d!@#$%^&*-]{8,}$/;
    return re.test(String(password));
}

/**
 * This function uses relaxed version of the URL validation regular expression.
 * It is used by the BlockingPolicy for validating Alternative Blocking URLs.
 *
 *  Example of a URL that should pass validation:
 *  https://servedby.flashtalking.com/imp/8/216832;7607468;201;jsiframe;TestSiteUSTech;300x250OnPageHTML5Tag2
 *
 *  Known side effect - simplified URLs like "https://example" are also treated as valid due to the lenient nature of the regex.
 */
export function isUrlValid(url) {
    const re = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return re.test(url);
}

export function sortOrAddFromSortedArray(array, orderedArray, valueToAdd) {
    return orderedArray.filter(val => val === valueToAdd || array.indexOf(val) >= 0)
}

export function downloadFileFromUrl(url, csvFileName = 'report.csv') {
    let link = document.createElement("a");
    link.download = csvFileName;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function initializeReactGA() {
    ReactGA.initialize([{
        trackingId: googleAnalyticsConfig.trackerId,
        gaOptions: {name: 'ga4Tracker'},
    },
        {
            trackingId: googleAnalyticsConfig.uaTrackerId,
            gaOptions: {name: 'uaTracker'}
        }
    ]);
    ReactGA.send({hitType: "pageview", page: googleAnalyticsConfig.defaultPageView}, gaTrackersNames);
}


export function fireEventToGoogleAnalytics(category, action, label) {
    let args = {
        category: category,
        action: action
    };
    if (label) {
        args.label = label
    }
    ReactGA.event(args, gaTrackersNames)
}

export function validateEmail(email) {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function extractValueFromBrackets(value) {
    let extractedValue = value;
    if (value) {
        const valueBracketsIndex = value.endsWith(']') ? value.lastIndexOf('[') : -1;
        if (valueBracketsIndex >= 0) {
            extractedValue = [value.slice(valueBracketsIndex + 1, value.length - 1)]
        }
    }
    return extractedValue
}

export function getConditionTextFromCondition(condition, fields, relationOperators) {
    const field = fields.find(field => field.key === condition.field);
    const fieldName = field ? field.title : condition.field;
    const operator = relationOperators[condition.relationOperator]
    if (!operator) {
        return 'Error: Operator not found'
    }
    const operatorName = operator.name
    if (condition.values && condition.values instanceof Array) {
        const valuesList = condition.values.map(value => `'${value}'`);
        if (valuesList.length > 1) {
            return `${fieldName} ${operatorName} (${valuesList.join(' or ')})`;
        }
        return `${fieldName} ${operatorName} ${valuesList[0]}`;
    } else if (condition.isPercentage) {
        return `${fieldName} ${operatorName} ${condition.value}%`;
    } else {
        return `${fieldName} ${operatorName} ${commaThousandsSeparator(condition.value)}`;
    }
}

export function getDimensionTitle(dimensionKey, dimensionsTitles, showOriginalKey) {
    let dimensionTitle;
    if (dimensionsTitles && dimensionsTitles[dimensionKey]) {
        dimensionTitle = dimensionsTitles[dimensionKey];
    } else {
        dimensionTitle = DIMENSIONS.find((dimension) => dimension.key === dimensionKey).title
    }
    return showOriginalKey ? `${dimensionTitle} (${dimensionKey})` : dimensionTitle;
}

export class ReportPropertiesQueryParameters {
    // Query Parameters format:
    // #pid=1000098&from=2019-08-12%2005:00:00&to=2019-08-12%2008:00:00&timezone=+00:00&dimensions=path,tid,operating_system,l3&path=contains:JS,IMG&operating_system=is:Other&af_fraud_bot_virus_percentage=ge:60&af_fraud_bot_virus=le:1000000&metrics=total,af_fraud,vi_ad_visibility_1
    constructor() {
        this.queryParametersList = decodeURIComponent(window.location.hash.substr(1)).split('&');
    }

    getFromQueryParameters(parameterName) {
        let queryString = this.queryParametersList.find(
            (parameter) => parameter.startsWith(`${parameterName}=`));
        return queryString ? queryString.slice(queryString.indexOf('=') + 1) : undefined
    }

    getAllFromQueryParameters(parameterName) {
        let queryStrings = this.queryParametersList.filter(
            (parameter) => parameter.startsWith(`${parameterName}=`));
        return queryStrings.map((string) => string.slice(string.indexOf('=') + 1))
    }

    getDateTime(parameterName, defaultValue) {
        let queryParameterValue = this.getFromQueryParameters(parameterName);
        return queryParameterValue && moment(queryParameterValue).isValid ? moment(queryParameterValue) : defaultValue
    }

    getList(parameterName, defaultValue) {
        let queryParameterValue = this.getFromQueryParameters(parameterName);
        return queryParameterValue ? queryParameterValue.split(',') : defaultValue
    }

    getPid(defaultValue) {
        return this.getFromQueryParameters('pid') || defaultValue
    }

    getFromDateTime(defaultValue) {
        return this.getDateTime('from', defaultValue)
    }

    getToDateTime(defaultValue) {
        return this.getDateTime('to', defaultValue)
    }

    getDimensions(defaultValue) {
        let dimensions = this.getList('dimensions', defaultValue);
        const pathIndex = dimensions.indexOf('path');
        if (pathIndex >= 0) {
            dimensions[pathIndex] = 'integration'
        }
        return dimensions
    }

    getMetrics(defaultValue) {
        return this.getList('metrics', defaultValue);
    }

    getTimeDimensions(defaultValue) {
        return this.getList('time_dimensions', defaultValue)
    }

    getTimeZone(defaultValue) {
        return this.getFromQueryParameters('timezone') || defaultValue
    }

    static getFiltersFromQueryString(field, conditionString, isFilter) {
        const separatorIndex = conditionString.indexOf(':');
        const conditionsProperties = [conditionString.slice(0, separatorIndex), conditionString.slice(separatorIndex + 1)];
        const relationOperators = isFilter ? FILTERS_RELATION_OPERATORS : CONDITIONS_RELATION_OPERATORS;
        const relationOperator = Object.keys(relationOperators).find(operator => relationOperators[operator].queryParameter === conditionsProperties[0]);
        let result = {
            field: field,
            relationOperator: relationOperator,
            isNumeric: isFilter
        }
        if (isFilter) {
            if (field.endsWith(PERCENTAGE_SUFFIX)) {
                result.field = field.slice(0, 0 - PERCENTAGE_SUFFIX.length)
                result.isPercentage = true
            }
            result.value = parseInt(conditionsProperties[1])
        } else {
            result.values = conditionsProperties[1].split(',')
        }
        return result
    }

    getFiltersForField(dimensionKey, isFilter) {
        let conditions = [];
        this.getAllFromQueryParameters(dimensionKey).map((conditionString) =>
            conditions = conditions.concat(
                ReportPropertiesQueryParameters.getFiltersFromQueryString(dimensionKey, conditionString, isFilter)));
        return conditions
    }

    getConditions(defaultDimensions, defaultValue) {
        let conditions = [];
        this.getDimensions(defaultDimensions).forEach(
            (dimension) => conditions = conditions.concat(this.getFiltersForField(dimension, false)));
        return conditions.length > 0 ? conditions : defaultValue
    }

    getFiltersAndSolutions() {
        let filters = this.getFiltersForField(TOTAL_COLUMN.key, true);
        let solutions = [];
        ALL_SOLUTIONS.forEach(solution => {
            let solutionFilters = [];
            if (metricBySolution[solution]) {
                metricBySolution[solution].forEach(metricGroup => {
                    metricGroup.masterMetrics.concat(metricGroup.metricsByCategories).forEach(metric => {
                        if (metric.cellType === 'NumberCell') {
                            solutionFilters = solutionFilters.concat(this.getFiltersForField(metric.key, true))
                        } else if (metric.cellType === 'NumberPercentageCell') {
                            solutionFilters = solutionFilters.concat(this.getFiltersForField(metric.key, true))
                            solutionFilters = solutionFilters.concat(this.getFiltersForField(`${metric.key}${PERCENTAGE_SUFFIX}`, true))
                        } else {
                            solutionFilters = solutionFilters.concat(this.getFiltersForField(`${metric.key}${PERCENTAGE_SUFFIX}`, true))
                        }
                    }, this)
                }, this)
            }
            if (solutionFilters.length > 0) {
                solutions.push(solution);
                filters = filters.concat(solutionFilters)
            }
        }, this)
        return {
            filteredSolutions: solutions,
            filters: filters
        }
    }

    static formatDateTime(dateTime) {
        return encodeURIComponent(dateTime.format('YYYY-MM-DD HH:mm:ss'))
    }

    static formatFilter(filter, relationOperators) {
        const field = filter.isPercentage ? `${filter.field}${PERCENTAGE_SUFFIX}` : filter.field;
        const relationOperator = relationOperators[filter.relationOperator].queryParameter;
        const values = filter.values && filter.values instanceof Array ? filter.values.map(encodeURIComponent).join(',') : filter.value;
        return `&${field}=${relationOperator}:${values}`
    }

    static formatFilters(filters, relationOperators) {
        let conditionsStrings = filters
            .map(filter => ReportPropertiesQueryParameters.formatFilter(filter, relationOperators));
        return conditionsStrings.join('')
    }

    static getQueryParametersFromReportProperties(reportProperties) {
        let pid = reportProperties.pid.pid;
        let from = ReportPropertiesQueryParameters.formatDateTime(reportProperties.timeRange.from);
        let to = ReportPropertiesQueryParameters.formatDateTime(reportProperties.timeRange.to);
        let timezone = encodeURIComponent(reportProperties.timeZone);
        let dimensions = reportProperties.dimensions.join(',');
        let timeDimensions = reportProperties.timeDimensions.length ?
            `&time_dimensions=${reportProperties.timeDimensions.join(',')}` : '';
        let metrics = `&metrics=${reportProperties.selectedMetrics.map((m) => m).join(',')}`
        let conditionsString = ReportPropertiesQueryParameters.formatFilters(reportProperties.conditions, CONDITIONS_RELATION_OPERATORS);
        let filtersString = ReportPropertiesQueryParameters.formatFilters(reportProperties.filters, FILTERS_RELATION_OPERATORS);
        return `pid=${pid}&from=${from}&to=${to}&timezone=${timezone}&dimensions=${dimensions}${timeDimensions}${conditionsString}${filtersString}${metrics}`
    }

    static replaceQueryParameters(url, queryParamName, value) {
        const startIndex = url.indexOf('&' + queryParamName);

        let endUrl = "";
        let startUrl = url;
        if (startIndex > -1) {
            startUrl = url.substr(0, startIndex);
            const endIndex = url.indexOf('&', startIndex + 1);
            if (endIndex > -1) {
                endUrl = url.substr(endIndex);
            }
        }

        return `${startUrl}&${queryParamName}=${value}${endUrl}`
    }
}

export function isPolicyExists(record, policyName) {
    return record[policyName] &&
        ((record[policyName]['block'] &&
                (record[policyName]['block'].length || Object.keys(record[policyName]['block']).length))
            || (record[policyName]['only'] &&
                (record[policyName]['only'].length || Object.keys(record[policyName]['only']))))
}

export function getInitialMetrics(isUserMaster, currentSolutions) {
    let fields = ['total']

    ALL_SOLUTIONS.forEach(solution => {
        if (SOLUTIONS.hasOwnProperty(solution) && currentSolutions.includes(solution)) {
            metricBySolution[solution].map(metricGroup => metricGroup.masterMetrics.concat(metricGroup.metricsByCategories)
                    .filter(metric => isUserMaster || !metric.forMasterUsers)
                    .map(metric => fields.push(metric.key))
            )
        }
    })
    return fields
}

export function getMetricsBySolution(isUserMaster, solution) {
    let metrics = []
    metricBySolution[solution].map((metricGroup) =>
        metricGroup.masterMetrics
            .concat(metricGroup.metricsByCategories)
            .filter((metric) => isUserMaster || !metric.forMasterUsers)
            .map((metric) => metrics.push(metric.key))
    )
    return metrics
}
