import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl';
import {Debounce} from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import {ConfigProvider as LocaleProvider, Layout} from 'antd';
import {ThemeProvider} from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Sidebar from '../../protectedMediaDashboardApp/containers/Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import {siteConfig} from '../../settings';
import {AppLocale} from '../../dashApp';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';
import './global.css';
import theme from "../../settings/themes/protectedmediatheme";
import UsersActions from "../../protectedMediaDashboardApp/redux/users/actions";
import PrintExtension from "../../protectedMediaDashboardApp/components/printExtension/PrintExtension";

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
const {userFetch} = UsersActions;


export class App extends Component {
    componentDidMount() {
        const {userFetch} = this.props;
        userFetch();
    }

    render() {
    const { url } = this.props.match;
    const { locale, selectedTheme, height } = this.props;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;
    return (
        <LocaleProvider locale={currentAppLocale.antd}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <ThemeProvider theme={themes[selectedTheme]}>
                    <AppHolder>
                        <Layout style={{height: appHeight}}>
                            <Debounce time="1000" handler="onResize">
                                <WindowResizeListener
                                    onResize={windowSize =>
                                        this.props.toggleAll(
                                            windowSize.windowWidth,
                                            windowSize.windowHeight
                                        )
                                    }
                                />
                            </Debounce>
                            <Topbar url={url}/>
                            <Layout style={{flexDirection: 'row', overflowX: 'hidden'}}>
                                <Sidebar url={url}/>
                                <Layout
                                    className="isoContentMainLayout"
                                    style={{
                                        height: height
                                    }}
                                >
                                    <Content
                                        className="isomorphicContent"
                                        style={{
                                            padding: '70px 0 0',
                        flexShrink: '0',
                        background: theme.palette.secondary[1],
                        position: 'relative'
                      }}
                    >
                      <AppRouter url={url}/>
                    </Content>
                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        borderTop: '1px solid #ededed'
                      }}
                    >
                      {siteConfig.footerText}
                      <p style={{fontSize: 11, color: '#abacb8'}}>
                          This is a stable, in production version of Protected Media's dashboard.
                          Please note that the MRC accredited metrics are available via our MRC feature only.
                          For further information please contact your CSM.
                      </p>
                    </Footer>
                    <PrintExtension/>
                  </Layout>
                </Layout>
                {/*<ThemeSwitcher/>*/}
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    locale: state.LanguageSwitcher.language.locale,
    selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
    isMaster: state.UsersFetch.isMaster,
    height: state.App.height
  }),
  { logout, userFetch, toggleAll }
)(App);
