import SuperFetch from './superFetch';
import ReactGA from 'react-ga4';
import {identify as FullStoryIdentify} from "react-fullstory";
import settings, {jwtConfig, useApiMock, gaTrackersNames} from "../settings";
import {getToken, isPolicyExists} from "./utility";
import {
    BLOCKING_POLICIES_RESPONSE_MOCK,
    DOCS_RESPONSE_MOCK,
    INTEGRATIONS_RESPONSE_MOCK,
    NOTIFICATIONS_RESPONSE_MOCK,
    REPORTS_RESPONSE_MOCK,
    USAGE_NOTIFICATIONS_RESPONSE_MOCK,
    USER_CONFIGURATIONS_RESPONSE_MOCK,
    USERS_RESPONSE_MOCK
} from "./fakeUserHelperData";
import TimeRangePicker from "../protectedMediaDashboardApp/components/filters/dateRangePicker";
import {
    CONDITIONS_RELATION_OPERATORS,
    FILTERS_RELATION_OPERATORS,
    PERCENTAGE_SUFFIX, SEPARATED_COLUMNS_DEFAULT,
} from '../protectedMediaDashboardApp/containers/dashboard/config'

class UserHelper {
    fetchUser = async () => {
        if (useApiMock) {
            return this.checkUser(USER_CONFIGURATIONS_RESPONSE_MOCK);
        }
        return await SuperFetch.get(`conf/`).then(response => {
            return this.checkUser(response);
        });
    };

    identifyUserForThirdPartyTools = (userData) => {
        ReactGA.set({
            userId: userData.ID,
            dimension1: userData.profile_type
        }, gaTrackersNames);

        if (process.env.NODE_ENV === 'production' && userData.profile_type !== 'M') {
            FullStoryIdentify(userData.ID, {
                displayName: userData.username,
                userType: userData.profile_type,
                email: userData.username
            });
        }
    };


    checkUser = data => {
        if (!data) {
            return {
                error: 'user is not exists',
            };
        }
        try {
            this.identifyUserForThirdPartyTools(data);
            const pidsDict = {};
            data.pids.forEach(pid => {
                pidsDict[pid.pid] = {
                    ...pid,
                    dimensionsTitles: pid.rewrite_ui_levels ? JSON.parse(pid.rewrite_ui_levels) : null,
                    logo: `${settings.apiUrl}${jwtConfig.fetchUrl}conf/dark_logo/${pid.pid}/?gtp=${getToken()}`,
                    enabled_features: pid.enabled_features || ''
                }
            });
            return {
                pids: pidsDict,
                isMaster: data.profile_type === 'M',
                name: `${data.first_name} ${data.last_name}`,
                username: data.username,
                updateBlockingPermissions: data.blocking_update_permissions
            }
        } catch (e) {
            console.log(e);

            return {error: 'Server Error'};
        }
    };

    fetchNotifications = async () => {
        if (useApiMock) {
            return this.checkNotifications(NOTIFICATIONS_RESPONSE_MOCK);
        }
        return await SuperFetch.get(`notifications/`).then(response => {
            return this.checkNotifications(response);
        });
    };

    checkNotifications = data => {
        if (!Array.isArray(data)) {
            return {
                error: 'Error while getting notifications from server',
            };
        }
        try {
            return {
                notifications: data
            }
        } catch (e) {
            console.log(e);

            return {error: 'Server Error'};
        }
    };

    fetchDocs = async () => {
        if (useApiMock) {
            return this.checkDocs(DOCS_RESPONSE_MOCK);
        }
        return await SuperFetch.get(`docs/get_docs`).then(response => {
            return this.checkDocs(response);
        });
    };

    checkDocs = data => {
        if (!Array.isArray(data)) {
            return {
                error: 'Error while getting docs from server',
            };
        }
        try {
            return {
                docs: data
            }
        } catch (e) {
            console.log(e);

            return {error: 'Server Error'};
        }
    };

    fetchReports = async () => {
        if (useApiMock) {
            return this.checkReports(REPORTS_RESPONSE_MOCK);
        }
        return await SuperFetch.get(`reports/get_reports`).then(response => {
            return this.checkReports(response);
        });
    };

    checkReports = data => {
        if (!Array.isArray(data)) {
            return {
                error: 'Error while getting reports from server',
            };
        }
        try {
            return {
                reports: data.map(this.reportPropertiesObjFromAlertData)
            };
        } catch (e) {
            console.log(e);

            return {error: 'Server Error'};
        }
    };

    fetchUsageNotifications = async () => {
        if (useApiMock) {
            return this.checkUsageNotifications(USAGE_NOTIFICATIONS_RESPONSE_MOCK);
        }
        return await SuperFetch.get(`reports/get_usage_notifications`).then(response => {
            return this.checkUsageNotifications(response);
        });
    };

    checkUsageNotifications = data => {
        if (!Array.isArray(data)) {
            return {
                error: 'Error while getting reports from server',
            };
        }
        try {
            return {
                usageNotifications: data.map(this.reportPropertiesObjFromAlertData)
            };
        } catch (e) {
            console.log(e);

            return {error: 'Server Error'};
        }
    };

    conditionJsonFromConditionObject = (condition, relationOperators, isNumericValue) => {
        const value = typeof condition.value === "string" ? condition.value.split(',') : condition.values || condition.value;
        let newCondition = {
            ...condition,
            relation_operator: condition.relationOperator,
            field: condition.isPercentage ? `${condition.field}${PERCENTAGE_SUFFIX}` : condition.field,
            values: value,
            value: value
        }
        if (isNumericValue !== undefined) {
            newCondition.is_value_numeric = isNumericValue
        }
        return newCondition
    };

    getSavedReportProperties = (savedReportProperties) => {
        let formData = new FormData();
        if (savedReportProperties.id) {
            formData.append("id", savedReportProperties.id);
        }
        formData.append("name", savedReportProperties.name);
        formData.append("description", savedReportProperties.description);
        formData.append("mailing_list", savedReportProperties.alertMailingList.join(';'));
        formData.append("enabled", savedReportProperties.isAlertEnabled);
        formData.append("csv_attachments_enabled", savedReportProperties.isAlertCSVEnabled);
        formData.append("sample_interval_period", savedReportProperties.sampleIntervalPeriod);
        formData.append("relative_time_range", savedReportProperties.reportProperties.relativeTimeRange);
        formData.append("timezone", savedReportProperties.reportProperties.timeZone);
        formData.append("alert_reminder_hours", savedReportProperties.alertReminderDays * 24);
        formData.append('is_resolved_enabled', savedReportProperties.isResolvedEnabled);
        formData.append('parameters_mapping_columns', savedReportProperties.reportProperties.parametersMappingColumns || SEPARATED_COLUMNS_DEFAULT);

        if(savedReportProperties.sampleIntervalStartDay){
            formData.append("sample_interval_start_day", savedReportProperties.sampleIntervalStartDay);
        }

        if (savedReportProperties.usageNotificationData && savedReportProperties.usageNotificationData.threshold) {
            formData.append("is_usage_notification", "true")
            formData.append("alert_data", JSON.stringify({
                is_fixed: savedReportProperties.usageNotificationData.isFixed,
                WARNING: savedReportProperties.usageNotificationData.threshold
            }))
        } else {
            formData.append("alert_data", JSON.stringify({
                dimensions: savedReportProperties.reportProperties.dimensions,
                time_dimensions: savedReportProperties.reportProperties.timeDimensions,
                metrics: savedReportProperties.reportProperties.selectedMetrics,
                filters: savedReportProperties.reportProperties.filters ?
                    savedReportProperties.reportProperties.filters.map((condition) => this.conditionJsonFromConditionObject(condition, FILTERS_RELATION_OPERATORS)) : [],
                conditions: savedReportProperties.reportProperties.conditions ?
                    savedReportProperties.reportProperties.conditions.map((condition) => this.conditionJsonFromConditionObject(condition, CONDITIONS_RELATION_OPERATORS)) : []
            }));
        }
        return formData;
    };

    checkSavedReportResponse = (response) => {
        if (response.status === 'success') {
            return {
                status: 'success',
                reportType: response.alert_type,
                id: response.id
            };
        }
        return {error: 'Error while saving report',};
    };

    updateSavedReport = async (savedReportProperties) => {
        let url = `reports/${savedReportProperties.reportProperties.pid.pid}/save/`;
        const data = this.getSavedReportProperties(savedReportProperties);
        return await SuperFetch.post(url, data).then(response => {
            return this.checkSavedReportResponse(response);
        });
    };

    conditionObjFromCondition = (condition) => {
        let value = condition.values;
        if (!value) {
            if (typeof condition.value === "string") {
                value = condition.value.split(',')
            } else if (Array.isArray(condition.values)) {
                value = condition.value;
            } else {
                value = condition.value
            }
        }

        if (condition.field.endsWith(PERCENTAGE_SUFFIX)) {
            condition.field = condition.field.slice(0, 0 - PERCENTAGE_SUFFIX.length)
            condition.isPercentage = true
        }

        return {
            ...condition,
            relationOperator: condition.relation_operator,
            values: value
        }
    };

    parseReportsPropertiesFromAlertData = (alertData) => (
        {
            ...alertData.alert_data,
            pid: {
                ...alertData.pid,
                dimensionsTitles: alertData.pid.rewrite_ui_levels ? JSON.parse(alertData.pid.rewrite_ui_levels) : null
            },
            timeZone: alertData.time_zone || alertData.timezone || TimeRangePicker.defaultProps.timeZone,
            dimensions: alertData.alert_data.dimensions,
            timeDimensions: alertData.alert_data.time_dimensions,
            conditions: alertData.alert_data.conditions ?
                alertData.alert_data.conditions.map(this.conditionObjFromCondition) : [],
            filters: alertData.alert_data.filters ?
                alertData.alert_data.filters.map(this.conditionObjFromCondition) : []
        }
    )

    reportPropertiesObjFromAlertData = (alertData) => {
        let savedReportProperties = {
            ...alertData,
            id: alertData.alert_configuration_id,
            alertType: alertData.alert_type,
            name: alertData.alert_name,
            description: alertData.alert_description,
            isAlertEnabled: alertData.enabled,
            isAlertCSVEnabled: alertData.csv_attachments_enabled,
            alertMailingList: alertData.mailing_list.split(';'),
            sampleIntervalPeriod: alertData.sample_interval_period,
            sampleIntervalStartDay: alertData.sample_interval_start_day,
            relativeTimeRange: alertData.relative_time_range,
            timeZone: alertData.timezone,
            alertReminderDays: alertData.alert_interval_period / 24,
            lastModificationTime: alertData.last_modification_time,
            isResolvedEnabled: alertData.is_resolved_enabled,
            parametersMappingColumns: alertData.parameters_mapping_columns
        }
        if (alertData.alert_type === 'traffic_alert') {
            savedReportProperties.reportProperties =
                this.parseReportsPropertiesFromAlertData(alertData)
        } else {
            savedReportProperties.usageNotificationData = {
                isFixed: alertData.alert_data.is_fixed,
                threshold: alertData.alert_data.WARNING
            };
            savedReportProperties.reportProperties = {
                pid: alertData.pid
            }
        }
        return savedReportProperties;
    };

    removeSavedReport = async (savedReportProperties) => {
        let url = `reports/${savedReportProperties.reportProperties.pid.pid}/delete/${savedReportProperties.id}/`;
        return await SuperFetch.delete(url).then(response => {
            return this.checkSavedReportRemoveResponse(response);
        });
    };

    checkSavedReportRemoveResponse = data => {
        if (data !== "deleted successfully") {
            return {
                error: 'Error while deleting report',
            };
        }
        return true;
    };

    switchUsageNotification = async (usageNotification) => {
        let url = `alerts/${usageNotification.reportProperties.pid.pid}/enable_or_disable/${usageNotification.id}/`;
        return await SuperFetch.post(url).then(response => {
            return this.checkSavedReportSwitchResponse(response);
        });
    };

    checkSavedReportSwitchResponse = data => {
        if (!data) {
            return {
                error: 'Error while switching report',
            };
        }
        return true;
    };

    fetchUsers = async () => {
        if (useApiMock) {
            return this.checkUsers(USERS_RESPONSE_MOCK);
        }
        return await SuperFetch.get(`users/`).then(response => {
            return this.checkUsers(response);
        });
    };

    checkUsers = data => {
        if (!data.users || !Array.isArray(data.users)) {
            return {
                error: 'Error while getting users from server',
            };
        }
        try {
            return {
                users: data.users.map(userData => ({
                    id: userData.ID,
                    username: userData.username,
                    firstName: userData.first_name,
                    lastName: userData.last_name || "",
                    profileType: userData.profile_type,
                    isActive: userData.is_active,
                    pids: userData.pids.map(pid => ({
                        ...pid,
                        userRestrictions: {
                            conditions: pid.user_restrictions && pid.user_restrictions.conditions ?
                                pid.user_restrictions.conditions.map(this.conditionObjFromCondition) : [],
                            dimensionsToEmpty: pid.user_restrictions && (pid.user_restrictions.dimensions_to_empty || [])
                        }
                    })),
                }))
            }
        } catch (e) {
            console.log(e);

            return {error: 'Server Error'};
        }
    };

    getUserPropertiesForm = (userProperties) => {
        let formData = new FormData();
        formData.append("fname", userProperties.firstName);
        formData.append("lname", userProperties.lastName);
        if (userProperties.newPassword) {
            formData.append("psswd", userProperties.newPassword);
        }
        if (userProperties.currentPassword) {
            formData.append("current_psswd", userProperties.currentPassword);
        }
        if (!userProperties.id) {
            formData.append("uname", userProperties.username);
        }
        if (userProperties.pids) {
            const pids = userProperties.pids.map(pid => ({
                ...pid,
                user_restrictions: {
                    conditions: pid.userRestrictions.conditions.map(
                        (condition) => this.conditionJsonFromConditionObject(condition, CONDITIONS_RELATION_OPERATORS,
                            false)),
                    dimensions_to_empty: pid.userRestrictions.dimensionsToEmpty
                }
            }))
            formData.append("pids", JSON.stringify(pids));
        }
        return formData;
    }

    updateUser = async (userProperties) => {
        const data = this.getUserPropertiesForm(userProperties);
        if (userProperties.id) {
            return await SuperFetch.put(`users/${userProperties.id}/`, data).then(response => {
                return response;
            });
        } else {
            return await SuperFetch.post(`users/`, data).then(response => {
                return response;
            });
        }
    };


    fetchIntegrations = async () => {
        if (useApiMock) {
            return this.checkIntegrations(INTEGRATIONS_RESPONSE_MOCK);
        }
        return await SuperFetch.get(`integration/get_integrations`).then(response => {
            return this.checkIntegrations(response);
        });
    };

    toIntegrationParameterObject = (parameter) => ({
        ...parameter,
        isMandatory: parameter.is_mandatory,
        validFormat: parameter.valid_format,
        possibleValues: parameter.possible_values,
        exampleValues: parameter.example_values
    });

    toIntegrationObject = (integration) => ({
        ...integration,
        deleteDate: integration.delete_date,
        creationTime: integration.creation_time,
        createdBy: integration.created_by,
        lastModificationTime: integration.last_modification_time,
        lastModifiedBy: integration.last_modified_by
    });

    checkIntegrations = data => {
        if (!data.integration_parameters) {
            return {
                error: 'Error while getting integrations from server',
            };
        }
        try {
            return {
                integrationParameters: data.integration_parameters.map(this.toIntegrationParameterObject),
                platforms: data.platforms,
                integrations: data.integrations.map(this.toIntegrationObject)
            }
        } catch (e) {
            console.log(e);

            return {error: 'Server Error'};
        }
    };

    checkIntegrationResponse = data => {
        if (data.status === 'success') {
            return {status: 'success'};
        }
        return {
            error: 'Error while saving integration',
        };
    };

    getIntegrationProperties = (integration) => {
        let formData = new FormData();
        if (integration.platform) {
            formData.append("platform", integration.platform);
        }
        if (integration.description) {
            formData.append("description", integration.description);
        }
        formData.append("name", integration.name);
        formData.append("environments", integration.environments.join(','));
        formData.append("parameters", JSON.stringify(integration.parameters));
        return formData;
    }

    updateIntegration = async (integration) => {
        let url = `integration/save/${integration.parameters.pid}/${integration.name}/`;
        const data = this.getIntegrationProperties(integration);
        return await SuperFetch.post(url, data).then(response => {
            return this.checkIntegrationResponse(response);
        });
    };

    removeIntegration = async (integration) => {
        let url = `integration/delete/${integration.parameters.pid}/${integration.name}/`;
        return await SuperFetch.delete(url).then(response => {
            return this.checkRemoveResponse(response);
        });
    };

    checkRemoveResponse = data => {
        if (data !== "deleted successfully") {
            return {
                error: 'Error while deleting',
            };
        }
        return true;
    };

    fetchBlockingPolicies = async () => {
        if (useApiMock) {
            return this.checkBlockingPolicies(BLOCKING_POLICIES_RESPONSE_MOCK);
        }
        return await SuperFetch.get(`blocking_policy/get_blocking_policies`).then(response => {
            return this.checkBlockingPolicies(response);
        });
    };

    geo_language_policy = (policy) => {
        var newPolicy = policy;
        if (policy && policy['block']) {
            newPolicy['block'] = policy['block'].map(code => code.toUpperCase());
        }
        if (policy && policy['only']) {
            newPolicy['only'] = policy['only'].map(code => code.toUpperCase());
        }
        return newPolicy
    }

    toBlockingPolicyObject = (blockingPolicy) => ({
        ...blockingPolicy,
        policyId: blockingPolicy.policy_id,
        description: blockingPolicy.description || '',
        altBlockIframeUrl: blockingPolicy.alt_block_iframe_url || '',
        exceptionUrlLists: blockingPolicy.exception_url_lists || [],
        categoriesPolicy: blockingPolicy.categories_policy,
        customCategoriesPolicy: blockingPolicy.custom_categories_policy,
        urlListsPolicy: blockingPolicy.url_lists_policy,
        bundleListsPolicy: blockingPolicy.bundle_lists_policy,
        geoPolicy: this.geo_language_policy(blockingPolicy.geo_policy),
        subdivisionPolicy: this.geo_language_policy(blockingPolicy.subdivision_policy),
        metroPolicy: this.geo_language_policy(blockingPolicy.metro_policy),
        zipPolicy: this.geo_language_policy(blockingPolicy.zip_policy),
        languagePolicy: this.geo_language_policy(blockingPolicy.language_policy),
        ivtPolicy: blockingPolicy.ivt_policy,
        sentimentPolicy: blockingPolicy.sentiment_policy,
        creationTime: blockingPolicy.creation_time,
        createdBy: blockingPolicy.creation_user,
        lastModificationTime: blockingPolicy.update_time,
        lastModifiedBy: blockingPolicy.update_user,
        conditions: blockingPolicy.conditions ?
            blockingPolicy.conditions.map(this.conditionObjFromCondition) : [],
    });

    toBlockingListObject = (customCategories) => ({
        ...customCategories,
        categoryId: customCategories.category_id,
        id: customCategories.category_id || customCategories.id,
        creationTime: customCategories.creation_time,
        createdBy: customCategories.creation_user,
        lastModificationTime: customCategories.update_time,
        lastModifiedBy: customCategories.update_user,
    });

    checkBlockingPolicies = data => {
        if (!data.blocking_policies) {
            return {
                error: 'Error while getting blocking policies from server',
            };
        }
        try {
            return {
                blockingPolicies: data.blocking_policies.map(this.toBlockingPolicyObject),
                customCategories: data.custom_categories.map(this.toBlockingListObject),
                urlLists: data.url_lists.map(this.toBlockingListObject),
                bundleLists: data.bundle_lists.map(this.toBlockingListObject),
            }
        } catch (e) {
            console.log(e);

            return {error: 'Server Error'};
        }
    };

    blockingPolicyProperties = (blockingPolicy) => {
        let formData = new FormData();
        if (blockingPolicy.policyId) {
            formData.append("policy_id", blockingPolicy.policyId);
        }
        formData.append("name", blockingPolicy.name);
        formData.append("description", blockingPolicy.description);
        formData.append("monitoring", Number(blockingPolicy.monitoring));
        formData.append("standalone", Number(blockingPolicy.standalone));
        formData.append("alt_block_iframe_url", blockingPolicy.altBlockIframeUrl);
        formData.append("exception_url_lists", JSON.stringify(blockingPolicy.exceptionUrlLists));

        const conditions = blockingPolicy.conditions ?
            blockingPolicy.conditions.map((condition) =>
                this.conditionJsonFromConditionObject(condition, CONDITIONS_RELATION_OPERATORS, false)) : []
        formData.append("conditions", JSON.stringify(conditions));
        formData.append("modes", blockingPolicy.modes);
        if (isPolicyExists(blockingPolicy, 'categoriesPolicy')) {
            formData.append("categories_policy", JSON.stringify(blockingPolicy.categoriesPolicy));
        }
        if (isPolicyExists(blockingPolicy, 'customCategoriesPolicy')) {
            formData.append("custom_categories_policy", JSON.stringify(blockingPolicy.customCategoriesPolicy));
        }
        if (isPolicyExists(blockingPolicy, 'urlListsPolicy')) {
            formData.append("url_lists_policy", JSON.stringify(blockingPolicy.urlListsPolicy));
        }
        if (isPolicyExists(blockingPolicy, 'bundleListsPolicy')) {
            formData.append("bundle_lists_policy", JSON.stringify(blockingPolicy.bundleListsPolicy));
        }
        if (isPolicyExists(blockingPolicy, 'geoPolicy')) {
            formData.append("geo_policy", JSON.stringify(blockingPolicy.geoPolicy));
        }
        if (isPolicyExists(blockingPolicy, 'subdivisionPolicy')) {
            formData.append("subdivision_policy", JSON.stringify(blockingPolicy.subdivisionPolicy));
        }
        if (isPolicyExists(blockingPolicy, 'metroPolicy')) {
            formData.append("metro_policy", JSON.stringify(blockingPolicy.metroPolicy));
        }
        if (isPolicyExists(blockingPolicy, 'zipPolicy')) {
            formData.append("zip_policy", JSON.stringify(blockingPolicy.zipPolicy));
        }
        if (isPolicyExists(blockingPolicy, 'languagePolicy')) {
            formData.append("language_policy", JSON.stringify(blockingPolicy.languagePolicy));
        }
        if (isPolicyExists(blockingPolicy, 'ivtPolicy')) {
            formData.append("ivt_policy", JSON.stringify(blockingPolicy.ivtPolicy));
        }
        if (isPolicyExists(blockingPolicy, 'sentimentPolicy')) {
            formData.append("sentiment_policy", JSON.stringify(blockingPolicy.sentimentPolicy));
        }
        return formData;
    }

    checkUpdateResponse = data => {
        if (data.status === 'success') {
            return {status: 'success'};
        }
        if (data.message) {
            return {
                error: true,
                message: data.message,
            };
        }
        return {
            error: 'Error while saving',
        };
    };

    updateBlockingPolicy = async (blockingPolicy) => {
        let url = `blocking_policy/save/${blockingPolicy.pid.pid}/`;
        const data = this.blockingPolicyProperties(blockingPolicy);
        return await SuperFetch.post(url, data).then(response => {
            return this.checkUpdateResponse(response);
        });
    };

    removeBlockingPolicy = async (blockingPolicy) => {
        let url = `blocking_policy/delete/${blockingPolicy.pid.pid}/${blockingPolicy.policyId}/`;
        return await SuperFetch.delete(url).then(response => {
            return this.checkRemoveResponse(response);
        });
    };

    blockingListProperties = (blockingList) => {
        let formData = new FormData();
        if (blockingList.id) {
            formData.append("id", blockingList.id);
        }
        formData.append("name", blockingList.name);
        formData.append("items", JSON.stringify(blockingList.items));
        return formData;
    }


    updateBlockingList = async (blockingPolicy, blockingListType) => {
        let url = `blocking_policy/save/${blockingListType}/${blockingPolicy.pid.pid}/`;
        const data = this.blockingListProperties(blockingPolicy);
        return await SuperFetch.post(url, data).then(response => {
            return this.checkUpdateResponse(response);
        });
    };

    removeBlockingList = async (blockingPolicy, blockingListType) => {
        let url = `blocking_policy/delete/${blockingListType}/${blockingPolicy.pid.pid}/${blockingPolicy.id}/`;
        return await SuperFetch.delete(url).then(response => {
            return this.checkRemoveResponse(response);
        });
    };


}

export default new UserHelper();
