import { SOLUTIONS } from '../../config'
import clone from 'clone'
import { TIME_UNITS } from '../../components/filters/dateRangePicker'
import theme from '../../../settings/themes/protectedmediatheme'

const CONDITIONS_RELATION_OPERATORS = {
    contains: { name: 'Contains', queryParameter: 'contains' },
    '==': { name: 'Is', queryParameter: 'is' },
    '!=': { name: 'Is Not', queryParameter: 'is_not' },
    not_contains: { name: 'Does Not Contain', queryParameter: 'not_contains' },
}

const MINIMUM_IMPRESSIONS_FOR_SAMPLE = 4000

const FILTERS_RELATION_OPERATORS = {
    '>=': { name: '\u2265', queryParameter: 'ge' },
    '<=': { name: '\u2264', queryParameter: 'le' },
    '=': { name: '=', queryParameter: 'eq' },
}

const SEPARATED_COLUMNS_DEFAULT = '{new_value} [{original_value}]'

const SEPARATED_COLUMNS_OPTIONS = {
        '{new_value} [{original_value}]': 'Single Column Containing Original Value And New Value',
        'Separated Columns': 'Separated Columns',
        '{new_value}': 'New Value Only'
    }

const PERCENTAGE_SUFFIX = '_percentage'

const DEFAULT_RELATIVE_TIME_RANGE = 'Yesterday'
export const presetTimeRanges = (currentMoment, isTodayIncluded) => ({
    'Last Hour': [
        clone(currentMoment)
            .subtract(2, TIME_UNITS.HOUR)
            .startOf(TIME_UNITS.HOUR),
        clone(currentMoment)
            .subtract(2, TIME_UNITS.HOUR)
            .endOf(TIME_UNITS.HOUR),
    ],
    Today: [
        clone(currentMoment).startOf(TIME_UNITS.DAY),
        clone(currentMoment).endOf(TIME_UNITS.DAY),
    ],

    Yesterday: [
        clone(currentMoment)
            .subtract(1, TIME_UNITS.DAY)
            .startOf(TIME_UNITS.DAY),
        clone(currentMoment).subtract(1, TIME_UNITS.DAY).endOf(TIME_UNITS.DAY),
    ],

    'Last 3 Days': [
        clone(currentMoment)
            .subtract(3, TIME_UNITS.DAY)
            .startOf(TIME_UNITS.DAY),
        isTodayIncluded
            ? clone(currentMoment).endOf(TIME_UNITS.DAY)
            : clone(currentMoment)
                  .subtract(1, TIME_UNITS.DAY)
                  .endOf(TIME_UNITS.DAY),
    ],

    'Last 7 Days': [
        clone(currentMoment)
            .subtract(7, TIME_UNITS.DAY)
            .startOf(TIME_UNITS.DAY),
        isTodayIncluded
            ? clone(currentMoment).endOf(TIME_UNITS.DAY)
            : clone(currentMoment)
                  .subtract(1, TIME_UNITS.DAY)
                  .endOf(TIME_UNITS.DAY),
    ],

    'Last 14 Days': [
        clone(currentMoment)
            .subtract(14, TIME_UNITS.DAY)
            .startOf(TIME_UNITS.DAY),
        isTodayIncluded
            ? clone(currentMoment).endOf(TIME_UNITS.DAY)
            : clone(currentMoment)
                .subtract(1, TIME_UNITS.DAY)
                .endOf(TIME_UNITS.DAY),
    ],

    'Last 30 Days': [
        clone(currentMoment)
            .subtract(30, TIME_UNITS.DAY)
            .startOf(TIME_UNITS.DAY),
        isTodayIncluded
            ? clone(currentMoment).endOf(TIME_UNITS.DAY)
            : clone(currentMoment)
                  .subtract(1, TIME_UNITS.DAY)
                  .endOf(TIME_UNITS.DAY),
    ],

    'Current Month': [
        clone(currentMoment).startOf(TIME_UNITS.MONTH),
        isTodayIncluded
            ? clone(currentMoment).endOf(TIME_UNITS.DAY)
            : clone(currentMoment)
                  .subtract(1, TIME_UNITS.HOUR)
                  .endOf(TIME_UNITS.HOUR),
    ],

    'Last Month': [
        clone(currentMoment)
            .subtract(1, TIME_UNITS.MONTH)
            .startOf(TIME_UNITS.MONTH),
        clone(currentMoment)
            .subtract(1, TIME_UNITS.MONTH)
            .endOf(TIME_UNITS.MONTH),
    ],
})

const SAMPLE_INTERVAL_VALUES = [
    {key: 'hourly', title: 'Hourly'},
    {key: 'daily', title: 'Daily'},
    {key: 'weekly', title: 'Weekly'},
    {key: 'biweekly', title: 'Bi-Weekly'},
    {key: 'monthly', title: 'Monthly'},
];

const SAMPLE_INTERVAL_START_DAY_VALUES = [
    {key: 'mon', title: 'Monday'},
    {key: 'tue', title: 'Tuesday'},
    {key: 'wed', title: 'Wednesday'},
    {key: 'thu', title: 'Thursday'},
    {key: 'fri', title: 'Friday'},
    {key: 'sat', title: 'Saturday'},
    {key: 'sun', title: 'Sunday'}
]


const INTERVALS_WITH_MANDATORY_START_DAY = ['weekly', 'biweekly'];
const SAMPLE_INTERVAL_DEFAULT = SAMPLE_INTERVAL_VALUES[1].key;
const SAMPLE_INTERVAL_START_DAY_DEFAULT = SAMPLE_INTERVAL_START_DAY_VALUES[0].key;

const TIME_DIMENSIONS_PROPERTIES = [
    { title: 'Date', key: 'date', format: 'YYYY-MM-DD' },
    { title: 'Month', format: 'MM', key: 'month', group: 'Date Properties' },
    { title: 'Week', format: 'W', key: 'week', group: 'Date Properties' },
    { title: 'Day', format: 'DD', key: 'day', group: 'Date Properties' },
    { title: 'Hour', format: 'HH', key: 'hour', group: 'Time Properties' },
    { title: 'Hour Range', key: 'hour_range', group: 'Time Properties' },
    { title: 'Year', format: 'Y', key: 'year', group: 'Date Properties' },
]

const DIMENSIONS_PROPERTIES = [
    {
        title: 'Integration',
        key: 'integration',
        isDefault: true,
        bigQueryFieldName: 'request.cntx',
    },
    {
        title: 'Traffic ID',
        key: 'tid',
        isDefault: true,
        bigQueryPrefix: 'request.query',
        isIntegrationParameter: true,
    },
    {
        title: 'Level 1',
        key: 'l1',
        isDefault: true,
        bigQueryPrefix: 'request.query',
        isIntegrationParameter: true,
    },
    {
        title: 'Level 2',
        key: 'l2',
        isDefault: true,
        bigQueryPrefix: 'request.query',
        isIntegrationParameter: true,
    },
    {
        title: 'Level 3',
        key: 'l3',
        isDefault: true,
        bigQueryPrefix: 'request.query',
        isIntegrationParameter: true,
    },
    {
        title: 'Level 4',
        key: 'l4',
        isDefault: true,
        bigQueryPrefix: 'request.query',
        isIntegrationParameter: true,
    },
    {
        title: 'Level 5',
        key: 'l5',
        isDefault: true,
        bigQueryPrefix: 'request.query',
        isIntegrationParameter: true,
    },
    {
        title: 'Level 6',
        key: 'l6',
        isDefault: true,
        bigQueryPrefix: 'request.query',
        isIntegrationParameter: true,
    },
    {
        title: 'Level 7',
        key: 'l7',
        bigQueryPrefix: 'request.query',
        isIntegrationParameter: true,
        forFTAccounts: true,
    },
    {
        title: 'Level 8',
        key: 'l8',
        bigQueryPrefix: 'request.query',
        isIntegrationParameter: true,
        forFTAccounts: true,
    },
    {
        title: 'Operating System',
        key: 'operating_system',
        bigQueryFieldName: 'dimensions.os',
        description:
            "The operating system as per the client's user agent's declaration",
    },
    {
        title: 'Environment',
        key: 'environment',
        bigQueryFieldName: 'dimensions.environment',
        description:
            "The environment as per the client's user agent's declaration",
    },
    {
        title: 'Browser',
        key: 'browser',
        bigQueryPrefix: 'dimensions',
        description:
            "Which browsers (or browsers within an app) displayed the ads, as per the client's user agent's declaration",
    },
    {
        title: 'Creative Type',
        key: 'creative_type',
        bigQueryPrefix: 'dimensions',
    },
    {
        title: 'Host',
        key: 'host',
        bigQueryPrefix: 'dimensions',
        description:
            'Irrespective of the declared website, this is the actual website hostname which has been detected',
    },
    {
        title: 'App',
        key: 'app',
        bigQueryPrefix: 'dimensions',
        description:
            'App identifiers across all playstores, in the case of iOS apps, we provide the app name if the number is declared',
    },
    {
        title: 'Geo',
        key: 'geo',
        bigQueryPrefix: 'dimensions',
        description:
            'Detected geo of the user, and proxy when detected. Proxies are reported as Exit Geo (Original Geo)',
    },
    {
        title: 'Traffic Type (SSAI, DOOH)',
        key: 'traffic_type',
        bigQueryPrefix: 'dimensions',
        description:
            'Distinguish between traffic which originated on the client side, and traffic which originated ' +
            'from the server-side ad insertion (ad stitching) or digital out of home (DOOH) platforms.',
    },
    {
        title: 'Auto Refresh',
        key: 'auto_refresh',
        bigQueryPrefix: 'dimensions',
        description:
            "Automatically refreshing pages use HTML programming code to automatically reload the user's browser with an updated webpage after a specified time interval, including new ad impressions." +
            ' Values in (Brackets) are user attested',
    },
    {
        title: 'Continuous Play',
        key: 'continuous_play',
        bigQueryPrefix: 'dimensions',
        description:
            'Continuous Play (also referred to Post-Play and analogous to Auto-Play) refers to an OTT ' +
            'configuration that will play the next episode in a series or related content automatically after the ' +
            'end of previous content without user interaction. Values in (Brackets) are user attested',
    },
    {
        title: 'Detected Clicks (BETA)',
        key: 'is_detected_click',
        onlyIfSolutionsAreEnabled: ['quality'],
        bigQueryFieldName: 'IF(macro.CLICK_DETECTED, "clicked", "")',
        description: 'Declares if clicks detected',
    },
    {
        title: 'Traffic Source Type',
        key: 'traffic_source_type',
        bigQueryPrefix: 'dimensions',
        description:
            'Declares if the traffic is Organic, Affiliate, Referral, Search or Purchased',
    },
    {
        title: 'Signed Pixel',
        key: 'signed',
        onlyIfSolutionsAreEnabled: ['signed'],
        bigQueryFieldName:
            'IF(verification.valid = true, "Verified", IF(verification.valid is not null, "Signed", ""))',
        description:
            'Identifies if an impression has been signed by the publisher to verify its authenticity',
    },
    {
        title: 'Blocking Modes',
        key: 'blocking_modes',
        onlyIfSolutionsAreEnabled: ['brand'],
        bigQueryFieldName: 'dimensions.blocking_modes',
        description:
            'The blocking modes applied. For more details on our blocking modes' +
            " see 'Brand Safety Monitoring and Blocking Guide' document in the Docs section",
    },
    {
        title: 'Blocking Categories',
        key: 'blocking_categories',
        onlyIfSolutionsAreEnabled: ['brand'],
        bigQueryFieldName: 'dimensions.blocking_categories',
        description:
            'The classification of the blocked impressions and the tolerance levels. ' +
            "For more details on our blocking categories see 'Brand Safety Monitoring and Blocking Guide'" +
            ' document in the Docs section',
    },
    {
        title: 'Blocking Policy',
        key: 'blocking_policy',
        onlyIfSolutionsAreEnabled: ['brand'],
        bigQueryFieldName: 'dimensions.bp_blocked_policy',
        description: 'The blocking policy used to block the impressions',
    },
    {
        title: 'Custom Visibility Policy',
        key: 'custom_viewability',
        onlyIfSolutionsAreEnabled: ['view'],
        bigQueryFieldName: 'dimensions.custom_viewability',
        description:
            'The custom visibility policy used to detect the impressions visibility. ' +
            'The format of this dimension is: [{display-pixels-percentage}|{display-visibility-seconds}]' +
            '[{video-pixels-percentage}|{video-visibility-seconds}]',
    },
]

const addCellType = (dimensions) => {
    dimensions.map((dimension) => (dimension.cellType = 'TextCell'))
    return dimensions
}

const DIMENSIONS = addCellType(DIMENSIONS_PROPERTIES)

const TIME_DIMENSIONS = addCellType(TIME_DIMENSIONS_PROPERTIES)

const DEFAULT_TOTAL_KEY = 'total'

const ALL_SOLUTIONS = [
    'fraud',
    'ivt',
    'view',
    'brand',
    'quality',
    'sample_rate',
    'mrc',
    'sampled_mrc',
    'click_fraud',
    'signed',
    'unified',
    'engagement',
    // 'taxonomy',
]

const TOTAL_PROPERTIES = {
    title: 'Total Transactions',
    key: 'total',
    cellType: 'NumberCell',
    totalKey: undefined,
    description: 'Total number of recorded events',
}

function columnByMetricProperties(metricProperties) {
    return {
        title: metricProperties.title,
        description: metricProperties.description,
        key: metricProperties.key,
        borrowValueFromKey:
            metricProperties.borrowValueFromKey || metricProperties.key,
        cellType: metricProperties.cellType || 'NumberPercentageCell',
        totalKey: metricProperties.hasOwnProperty('totalKey')
            ? metricProperties.totalKey
            : DEFAULT_TOTAL_KEY,
        toggleHandler: metricProperties.toggleHandler,
        hasHint: metricProperties.hasHint,
        toggleText: metricProperties.toggleText,
        ignoreColoringPolicy: metricProperties.ignoreColoringPolicy,
        forMasterUsers: metricProperties.forMasterUsers,
        isDerived: metricProperties.isDerived,
        isMasterMetric: metricProperties.isMasterMetric,
    }
}

let metricBySolution = {}
Object.keys(SOLUTIONS).forEach((solution) => {
    metricBySolution[solution] = SOLUTIONS[solution].metricsGroups.map(
        (metricGroup) => ({
            title: metricGroup.title,
            groupKey: metricGroup.groupKey,
            description: metricGroup.description,
            coloringPolicy: metricGroup.coloringPolicy,
            containsOnlyMasterMetrics: metricGroup.containsOnlyMasterMetrics,
            masterMetrics: metricGroup.masterMetrics.map(
                columnByMetricProperties
            ),
            metricsByCategories: metricGroup.metricsByCategories.map(
                columnByMetricProperties
            ),
        })
    )
})

const TOTAL_COLUMN = columnByMetricProperties(TOTAL_PROPERTIES)
const METRICS_GROUPS = [columnByMetricProperties(TOTAL_PROPERTIES)].concat(
    Object.values(SOLUTIONS).reduce(
        (accumulator, currentValue) =>
            accumulator.concat(currentValue.metricsGroups),
        []
    )
)
const METRICS = METRICS_GROUPS.reduce(
    (accumulator, currentValue) =>
        currentValue.masterMetrics || currentValue.metricsByCategories
            ? accumulator
                  .concat(currentValue.masterMetrics || [])
                  .concat(currentValue.metricsByCategories || [])
            : accumulator.concat([currentValue]),
    []
)

const BLOCKING_MODES = {
    base: 'Basic',
    rt: 'Real-Time',
    js: 'JavaScript',
}

const BLOCKING_SEVERITIES = {
    low: {
        color: theme.palette.warning[0],
        value: 1,
        title: 'Low Blocking',
        description: 'Pass ads on pages deemed high, medium and low risk',
    },
    medium: {
        color: theme.palette.warning[1],
        value: 2,
        title: 'Medium Blocking',
        description: 'Pass ads on pages deemed medium and low risk',
    },
    high: {
        color: theme.palette.error[0],
        value: 3,
        title: 'High Blocking',
        description: 'Pass ads on pages deemed low risk',
    },
}

const IAB_CATEGORIES = {
    IAB1: 'Arts Entertainment',
    'IAB1-1': 'Books Literature',
    'IAB1-2': 'Celebrity Fan/Gossip',
    'IAB1-3': 'Fine Art',
    'IAB1-4': 'Humor',
    'IAB1-5': 'Movies',
    'IAB1-6': 'Music',
    'IAB1-7': 'Television',
    IAB2: 'Automotive',
    'IAB2-1': 'Auto Parts',
    'IAB2-2': 'Auto Repair',
    'IAB2-3': 'Buying/Selling Cars',
    'IAB2-4': 'Car Culture',
    'IAB2-5': 'Certified Pre-Owned',
    'IAB2-6': 'Convertible',
    'IAB2-7': 'Coupe',
    'IAB2-8': 'Crossover',
    'IAB2-9': 'Diesel',
    'IAB2-10': 'Electric Vehicle',
    'IAB2-11': 'Hatchback',
    'IAB2-12': 'Hybrid',
    'IAB2-13': 'Luxury',
    'IAB2-14': 'MiniVan',
    'IAB2-15': 'Mororcycles',
    'IAB2-16': 'Off-Road Vehicles',
    'IAB2-17': 'Performance Vehicles',
    'IAB2-18': 'Pickup',
    'IAB2-19': 'Road-Side Assistance',
    'IAB2-20': 'Sedan',
    'IAB2-21': 'Trucks Accessories',
    'IAB2-22': 'Vintage Cars',
    'IAB2-23': 'Wagon',
    IAB3: 'Business',
    'IAB3-1': 'Advertising',
    'IAB3-2': 'Agriculture',
    'IAB3-3': 'Biotech/Biomedical',
    'IAB3-4': 'Business Software',
    'IAB3-5': 'Construction',
    'IAB3-6': 'Forestry',
    'IAB3-7': 'Government',
    'IAB3-8': 'Green Solutions',
    'IAB3-9': 'Human Resources',
    'IAB3-10': 'Logistics',
    'IAB3-11': 'Marketing',
    'IAB3-12': 'Metals',
    IAB4: 'Careers',
    'IAB4-1': 'Career Planning',
    'IAB4-2': 'College',
    'IAB4-3': 'Financial Aid',
    'IAB4-4': 'Job Fairs',
    'IAB4-5': 'Job Search',
    'IAB4-6': 'Resume Writing/Advice',
    'IAB4-7': 'Nursing',
    'IAB4-8': 'Scholarships',
    'IAB4-9': 'Telecommuting',
    'IAB4-10': 'U.S. Military',
    'IAB4-11': 'Career Advice',
    IAB5: 'Education',
    'IAB5-1': '7-12 Education',
    'IAB5-2': 'Adult Education',
    'IAB5-3': 'Art History',
    'IAB5-4': 'College Administration',
    'IAB5-5': 'College Life',
    'IAB5-6': 'Distance Learning',
    'IAB5-7': 'English as a 2nd Language',
    'IAB5-8': 'Language Learning',
    'IAB5-9': 'Graduate School',
    'IAB5-10': 'Homeschooling',
    'IAB5-11': 'Homework/Study Tips',
    'IAB5-12': 'K-6 Educators',
    'IAB5-13': 'Private School',
    'IAB5-14': 'Special Education',
    'IAB5-15': 'Studying Business',
    IAB6: 'Family Parenting',
    'IAB6-1': 'Adoption',
    'IAB6-2': 'Babies Toddlers',
    'IAB6-3': 'Daycare/Pre School',
    'IAB6-4': 'Family Internet',
    'IAB6-5': 'Parenting - K-6 Kids',
    'IAB6-6': 'Parenting teens',
    'IAB6-7': 'Pregnancy',
    'IAB6-8': 'Special Needs Kids',
    'IAB6-9': 'Eldercare',
    IAB7: 'Health Fitness',
    'IAB7-1': 'Exercise',
    'IAB7-2': 'A.D.D.',
    'IAB7-3': 'AIDS/HIV',
    'IAB7-4': 'Allergies',
    'IAB7-5': 'Alternative Medicine',
    'IAB7-6': 'Arthritis',
    'IAB7-7': 'Asthma',
    'IAB7-8': 'Autism/PDD',
    'IAB7-9': 'Bipolar Disorder',
    'IAB7-10': 'Brain Tumor',
    'IAB7-11': 'Cancer',
    'IAB7-12': 'Cholesterol',
    'IAB7-13': 'Chronic Fatigue Syndrome',
    'IAB7-14': 'Chronic Pain',
    'IAB7-15': 'Cold Flu',
    'IAB7-16': 'Deafness',
    'IAB7-17': 'Dental Care',
    'IAB7-18': 'Depression',
    'IAB7-19': 'Dermatology',
    'IAB7-20': 'Diabetes',
    'IAB7-21': 'Epilepsy',
    'IAB7-22': 'GERD/Acid Reflux',
    'IAB7-23': 'Headaches/Migraines',
    'IAB7-24': 'Heart Disease',
    'IAB7-25': 'Herbs for Health',
    'IAB7-26': 'Holistic Healing',
    'IAB7-27': 'IBS/Crohn’s Disease',
    'IAB7-28': 'Incest/Abuse Support',
    'IAB7-29': 'Incontinence',
    'IAB7-30': 'Infertility',
    'IAB7-31': 'Men’s Health',
    'IAB7-32': 'Nutrition',
    'IAB7-33': 'Orthopedics',
    'IAB7-34': 'Panic/Anxiety Disorders',
    'IAB7-35': 'Pediatrics',
    'IAB7-36': 'Physical Therapy',
    'IAB7-37': 'Psychology/Psychiatry',
    'IAB7-38': 'Senior Health',
    'IAB7-39': 'Sexuality',
    'IAB7-40': 'Sleep Disorders',
    'IAB7-41': 'Smoking Cessation',
    'IAB7-42': 'Substance Abuse',
    'IAB7-43': 'Thyroid Disease',
    'IAB7-44': 'Weight Loss',
    'IAB7-45': 'Women’s Health',
    IAB8: 'Food Drink',
    'IAB8-1': 'American Cuisine',
    'IAB8-2': 'Barbecues Grilling',
    'IAB8-3': 'Cajun/Creole',
    'IAB8-4': 'Chinese Cuisine',
    'IAB8-5': 'Cocktails/Beer',
    'IAB8-6': 'Coffee/Tea',
    'IAB8-7': 'Cuisine-Specific',
    'IAB8-8': 'Desserts Baking',
    'IAB8-9': 'Dining Out',
    'IAB8-10': 'Food Allergies',
    'IAB8-11': 'French Cuisine',
    'IAB8-12': 'Health/Lowfat Cooking',
    'IAB8-13': 'Italian Cuisine',
    'IAB8-14': 'Japanese Cuisine',
    'IAB8-15': 'Mexican Cuisine',
    'IAB8-16': 'Vegan',
    'IAB8-17': 'Vegetarian',
    'IAB8-18': 'Wine',
    IAB9: 'Hobbies Interests',
    'IAB9-1': 'Art/Technology',
    'IAB9-2': 'Arts Crafts',
    'IAB9-3': 'Beadwork',
    'IAB9-4': 'Birdwatching',
    'IAB9-5': 'Board Games/Puzzles',
    'IAB9-6': 'Candle Soap Making',
    'IAB9-7': 'Card Games',
    'IAB9-8': 'Chess',
    'IAB9-9': 'Cigars',
    'IAB9-10': 'Collecting',
    'IAB9-11': 'Comic Books',
    'IAB9-12': 'Drawing/Sketching',
    'IAB9-13': 'Freelance Writing',
    'IAB9-14': 'Genealogy',
    'IAB9-15': 'Getting Published',
    'IAB9-16': 'Guitar',
    'IAB9-17': 'Home Recording',
    'IAB9-18': 'Investors Patents',
    'IAB9-19': 'Jewelry Making',
    'IAB9-20': 'Magic Illusion',
    'IAB9-21': 'Needlework',
    'IAB9-22': 'Painting',
    'IAB9-23': 'Photography',
    'IAB9-24': 'Radio',
    'IAB9-25': 'Roleplaying Games',
    'IAB9-26': 'Sci-Fi Fantasy',
    'IAB9-27': 'Scrapbooking',
    'IAB9-28': 'Screenwriting',
    'IAB9-29': 'Stamps Coins',
    'IAB9-30': 'Video Computer Games',
    'IAB9-31': 'Woodworking',
    IAB10: 'Home Garden',
    'IAB10-1': 'Appliances',
    'IAB10-2': 'Entertaining',
    'IAB10-3': 'Environmental Safety',
    'IAB10-4': 'Gardening',
    'IAB10-5': 'Home Repair',
    'IAB10-6': 'Home Theater',
    'IAB10-7': 'Interior Decorating',
    'IAB10-8': 'Landscaping',
    'IAB10-9': 'Remodeling Construction',
    IAB11: 'Law, Gov’t Politics',
    'IAB11-1': 'Immigration',
    'IAB11-2': 'Legal Issues',
    'IAB11-3': 'U.S. Government Resources',
    'IAB11-4': 'Politics',
    'IAB11-5': 'Commentary',
    IAB12: 'News',
    'IAB12-1': 'International News',
    'IAB12-2': 'National News',
    'IAB12-3': 'Local News',
    IAB13: 'Personal Finance',
    'IAB13-1': 'Beginning Investing',
    'IAB13-2': 'Credit/Debt Loans',
    'IAB13-3': 'Financial News',
    'IAB13-4': 'Financial Planning',
    'IAB13-5': 'Hedge Fund',
    'IAB13-6': 'Insurance',
    'IAB13-7': 'Investing',
    'IAB13-8': 'Mutual Funds',
    'IAB13-9': 'Options',
    'IAB13-10': 'Retirement Planning',
    'IAB13-11': 'Stocks',
    'IAB13-12': 'Tax Planning',
    IAB14: 'Society',
    'IAB14-1': 'Dating',
    'IAB14-2': 'Divorce Support',
    'IAB14-3': 'Gay Life',
    'IAB14-4': 'Marriage',
    'IAB14-5': 'Senior Living',
    'IAB14-6': 'Teens',
    'IAB14-7': 'Weddings',
    'IAB14-8': 'Ethnic Specific',
    IAB15: 'Science',
    'IAB15-1': 'Astrology',
    'IAB15-2': 'Biology',
    'IAB15-3': 'Chemistry',
    'IAB15-4': 'Geology',
    'IAB15-5': 'Paranormal Phenomena',
    'IAB15-6': 'Physics',
    'IAB15-7': 'Space/Astronomy',
    'IAB15-8': 'Geography',
    'IAB15-9': 'Botany',
    'IAB15-10': 'Weather',
    IAB16: 'Pets',
    'IAB16-1': 'Aquariums',
    'IAB16-2': 'Birds',
    'IAB16-3': 'Cats',
    'IAB16-4': 'Dogs',
    'IAB16-5': 'Large Animals',
    'IAB16-6': 'Reptiles',
    'IAB16-7': 'Veterinary Medicine',
    IAB17: 'Sports',
    'IAB17-1': 'Auto Racing',
    'IAB17-2': 'Baseball',
    'IAB17-3': 'Bicycling',
    'IAB17-4': 'Bodybuilding',
    'IAB17-5': 'Boxing',
    'IAB17-6': 'Canoeing/Kayaking',
    'IAB17-7': 'Cheerleading',
    'IAB17-8': 'Climbing',
    'IAB17-9': 'Cricket',
    'IAB17-10': 'Figure Skating',
    'IAB17-11': 'Fly Fishing',
    'IAB17-12': 'Football',
    'IAB17-13': 'Freshwater Fishing',
    'IAB17-14': 'Game Fish',
    'IAB17-15': 'Golf',
    'IAB17-16': 'Horse Racing',
    'IAB17-17': 'Horses',
    'IAB17-18': 'Hunting/Shooting',
    'IAB17-19': 'Inline Skating',
    'IAB17-20': 'Martial Arts',
    'IAB17-21': 'Mountain Biking',
    'IAB17-22': 'NASCAR Racing',
    'IAB17-23': 'Olympics',
    'IAB17-24': 'Paintball',
    'IAB17-25': 'Power Motorcycles',
    'IAB17-26': 'Pro Basketball',
    'IAB17-27': 'Pro Ice Hockey',
    'IAB17-28': 'Rodeo',
    'IAB17-29': 'Rugby',
    'IAB17-30': 'Running/Jogging',
    'IAB17-31': 'Sailing',
    'IAB17-32': 'Saltwater Fishing',
    'IAB17-33': 'Scuba Diving',
    'IAB17-34': 'Skateboarding',
    'IAB17-35': 'Skiing',
    'IAB17-36': 'Snowboarding',
    'IAB17-37': 'Surfing/Bodyboarding',
    'IAB17-38': 'Swimming',
    'IAB17-39': 'Table Tennis/Ping-Pong',
    'IAB17-40': 'Tennis',
    'IAB17-41': 'Volleyball',
    'IAB17-42': 'Walking',
    'IAB17-43': 'Waterski/Wakeboard',
    'IAB17-44': 'World Soccer',
    IAB18: 'Style Fashion',
    'IAB18-1': 'Beauty',
    'IAB18-2': 'Body Art',
    'IAB18-3': 'Fashion',
    'IAB18-4': 'Jewelry',
    'IAB18-5': 'Clothing',
    'IAB18-6': 'Accessories',
    IAB19: 'Technology Computing',
    'IAB19-1': '3-D Graphics',
    'IAB19-2': 'Animation',
    'IAB19-3': 'Antivirus Software',
    'IAB19-4': 'C/C++',
    'IAB19-5': 'Cameras Camcorders',
    'IAB19-6': 'Cell Phones',
    'IAB19-7': 'Computer Certification',
    'IAB19-8': 'Computer Networking',
    'IAB19-9': 'Computer Peripherals',
    'IAB19-10': 'Computer Reviews',
    'IAB19-11': 'Data Centers',
    'IAB19-12': 'Databases',
    'IAB19-13': 'Desktop Publishing',
    'IAB19-14': 'Desktop Video',
    'IAB19-15': 'Email',
    'IAB19-16': 'Graphics Software',
    'IAB19-17': 'Home Video/DVD',
    'IAB19-18': 'Internet Technology',
    'IAB19-19': 'Java',
    'IAB19-20': 'JavaScript',
    'IAB19-21': 'Mac Support',
    'IAB19-22': 'MP3/MIDI',
    'IAB19-23': 'Net Conferencing',
    'IAB19-24': 'Net for Beginners',
    'IAB19-25': 'Network Security',
    'IAB19-26': 'Palmtops/PDAs',
    'IAB19-27': 'PC Support',
    'IAB19-28': 'Portable',
    'IAB19-29': 'Entertainment',
    'IAB19-30': 'Shareware/Freeware',
    'IAB19-31': 'Unix',
    'IAB19-32': 'Visual Basic',
    'IAB19-33': 'Web Clip Art',
    'IAB19-34': 'Web Design/HTML',
    'IAB19-35': 'Web Search',
    'IAB19-36': 'Windows',
    IAB20: 'Travel',
    'IAB20-1': 'Adventure Travel',
    'IAB20-2': 'Africa',
    'IAB20-3': 'Air Travel',
    'IAB20-4': 'Australia New Zealand',
    'IAB20-5': 'Bed Breakfasts',
    'IAB20-6': 'Budget Travel',
    'IAB20-7': 'Business Travel',
    'IAB20-8': 'By US Locale',
    'IAB20-9': 'Camping',
    'IAB20-10': 'Canada',
    'IAB20-11': 'Caribbean',
    'IAB20-12': 'Cruises',
    'IAB20-13': 'Eastern Europe',
    'IAB20-14': 'Europe',
    'IAB20-15': 'France',
    'IAB20-16': 'Greece',
    'IAB20-17': 'Honeymoons/Getaways',
    'IAB20-18': 'Hotels',
    'IAB20-19': 'Italy',
    'IAB20-20': 'Japan',
    'IAB20-21': 'Mexico Central America',
    'IAB20-22': 'National Parks',
    'IAB20-23': 'South America',
    'IAB20-24': 'Spas',
    'IAB20-25': 'Theme Parks',
    'IAB20-26': 'Traveling with Kids',
    'IAB20-27': 'United Kingdom',
    IAB21: 'Real Estate',
    'IAB21-1': 'Apartments',
    'IAB21-2': 'Architects',
    'IAB21-3': 'Buying/Selling Homes',
    IAB22: 'Shopping',
    'IAB22-1': 'Contests Freebies',
    'IAB22-2': 'Couponing',
    'IAB22-3': 'Comparison',
    'IAB22-4': 'Engines',
    IAB23: 'Religion Spirituality',
    'IAB23-1': 'Alternative Religions',
    'IAB23-2': 'Atheism/Agnosticism',
    'IAB23-3': 'Buddhism',
    'IAB23-4': 'Catholicism',
    'IAB23-5': 'Christianity',
    'IAB23-6': 'Hinduism',
    'IAB23-7': 'Islam',
    'IAB23-8': 'Judaism',
    'IAB23-9': 'Latter-Day Saints',
    'IAB23-10': 'Pagan/Wiccan',
    IAB24: 'Uncategorized',
    IAB25: 'Non-Standard Content',
    'IAB25-1': 'Unmoderated UGC',
    'IAB25-2': 'Extreme Graphic/Explicit Violence',
    'IAB25-3': 'Pornography',
    'IAB25-4': 'Profane Content',
    'IAB25-5': 'Hate Content',
    'IAB25-6': 'Under Construction',
    'IAB25-7': 'Incentivized',
    IAB26: 'Illegal Content',
    'IAB26-1': 'Illegal Content',
    'IAB26-2': 'Warez',
    'IAB26-3': 'Spyware/Malware',
    'IAB26-4': 'Copyright Infringement',
}

export {
    TIME_DIMENSIONS_PROPERTIES,
    PERCENTAGE_SUFFIX,
    DIMENSIONS,
    TIME_DIMENSIONS,
    metricBySolution,
    METRICS_GROUPS,
    METRICS,
    TOTAL_COLUMN,
    ALL_SOLUTIONS,
    DEFAULT_TOTAL_KEY,
    CONDITIONS_RELATION_OPERATORS,
    MINIMUM_IMPRESSIONS_FOR_SAMPLE,
    SAMPLE_INTERVAL_VALUES,
    SAMPLE_INTERVAL_START_DAY_VALUES,
    SAMPLE_INTERVAL_DEFAULT,
    SAMPLE_INTERVAL_START_DAY_DEFAULT,
    INTERVALS_WITH_MANDATORY_START_DAY,
    DEFAULT_RELATIVE_TIME_RANGE,
    FILTERS_RELATION_OPERATORS,
    BLOCKING_MODES,
    BLOCKING_SEVERITIES,
    IAB_CATEGORIES,
    SEPARATED_COLUMNS_OPTIONS,
    SEPARATED_COLUMNS_DEFAULT,
}
